.googleMap {
    z-index: 1 !important;
    width: 900px;
    height: 300px;
}

.sidebarStyle {
    display: inline-block;
    position: absolute;
    margin: 10px;
    background-color: #005c99;
    color: #ffffff;
    z-index: 2 !important;
    padding: 1px 6px;
    font-weight: bold;
}
.sidebarStyle2 {
    display: inline-block;
    position: absolute;
    margin: 10px;
    margin-top: 40px;
    background-color: #094064;
    color: #ffffff;
    z-index: 2 !important;
    padding: 1px 6px;
    font-weight: bold;
}
.sidebarStyle3 {
    display: inline-block;
    position: absolute;
    margin: 10px;
    margin-top: 70px;
    background-color: #259ba3;
    color: #ffffff;
    z-index: 2 !important;
    padding: 1px 6px;
    font-weight: bold;
}
.sidebarStyle4 {
    display: inline-block;
    position: absolute;
    margin: 10px;
    margin-top: 100px;
    background-color: #266b4e;
    color: #ffffff;
    z-index: 2 !important;
    padding: 1px 6px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .googleMap {
        display: block;
        width: 550px;
        height: 400px;
    }
}

@media (max-width: 400px) {
    .googleMap {
        display: inline-block;
        width: 280px;
        height: 250px;
    }

    .sidebarStyle, .sidebarStyle2, .sidebarStyle3, .sidebarStyle4{
        position:relative;
        margin: 5px;
    }
}