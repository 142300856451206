@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  background: #e1edff;
}

h1,
h2 {
  margin-bottom: 15px;
}

ul {
  list-style-type: none;
}

button {
  background-color: #005c99;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  font-size: larger;
  margin: 10px;
  border: solid 2px;
}

.smallButton {
  font-size: small;
}

button:hover {
  color: #005c99;
  background-color: #fff;
  border: solid 2px;
}

button:hover a {
  color: #005c99;
}

button a {
  text-decoration: none;
  color: #fff;
}

#registration button {
  margin-top: 20px;
}

.link a {
  color: #005c99;
}

td {
  padding: 3px;
}

.container,
.boxes {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 70px;
}

.navbar {
  background: #005c99;
  color: #fff;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 3;
}

.navbar .logo {
  font-size: x-large;
  font-weight: bold;
}

.navbar button {
  border: none;
  background-color: transparent;
}

.navbar button img {
  max-width: 35%;
}

.navbar button img:hover {
  opacity: 0.7;
}

.navbar a {
  text-decoration: none;
  color: #fff;
}

.navbar a:hover {
  color: #c3d4ee;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar ul {
  display: flex;
  align-items: center;
}

.navbar ul li {
  margin-left: 20px;
}

.header {
  color: #fff;
  min-height: 500px;
}

.header h1 {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  margin-top: 50px;
  margin-bottom: 0px;
}

.header .lighter {
  opacity: 0.7;
  font-weight: lighter;
  margin-top: 470px;
}

.header .container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 65px;
}

.header h3 {
  margin-bottom: 45px;
}

.header .container {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.18)),
    url("ikarus-main-s.jpg");
  background-repeat: no-repeat;
  background-color: #e1edff;
  background-position: 0% 45%;
  background-size: cover;
  min-height: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  background: #005c99;
  color: #fff;
  height: 180px;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  color: #c3d4ee;
}

.footer .container {
  padding-top: 20px;
}

.footer .container a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 0 30px;
}

.footer div {
  display: flex;
  flex-direction: column;
}

.rights {
  text-align: center;
  font-size: x-small;
  margin-top: 20px;
}

.tableWidth {
  width: 13%;
}

.event h1 {
  color: #005c99;
  padding-top: 15px;
}

.event .container {
  background-color: #fff;
  padding-bottom: 15px;
}

.program h4 {
  width: -moz-fit-content;
  width: fit-content;
  display: table;
  padding: 2px 5px 0 5px;
  background-color: #005c99;
  color: #fff;
}

#approach tr th{
  background-color: #d9edfa;
}

#approach tr:hover {
  background-color: #d9edfa;
}

#approach th, td {
  padding: 8px;
  text-align: left;
}

.boxes .container {
  display: flex;
  justify-content: space-between;
}

#reg {
  background: #fff;
  color: #005c99;
}

#reg h1 {
  color: #005c99;
}

.regAndRules {
  display: block;
}

.regAndRules .boxes {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.noWrap {
  white-space: nowrap;
}

.regAndRules .boxHu {
  min-height: 310px;
}
.regAndRules .boxEn {
  min-height: 370px;
}

@media (max-width: 1095px) {
  .boxes {
    flex-direction: column;
    padding: 10px 0px;
    text-align: center;
    background-position: 33% 50%;
    min-height: 350px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: center;
    width: 300px !important;
  }
  
  .boxes a {
    display: inline-block;
  }
}

.box {
  flex: 1;
  background: #005c99;
  color: #fff;
  border-radius: 10px;
  margin: 20px 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6);
  padding: 15px 20px;
  text-align: center;
}

.box h1 {
  color: white;
}

input[type="submit"] {
  background-color: #005c99;
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  border: #fff solid 2px;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

input[type="number"] {
  width: 30%;
  padding: 12px 20px;
  border-radius: 5px;
  border: none;
  margin: 8px 0;
  box-sizing: border-box;
}

::placeholder {
  color: #005c99;
  font-family: "Nunito", sans-serif;
}

.output {
  margin-top: 30px;
  border-top: 2px solid #fff;
  padding-top: 30px;
}

label {
  display: block;
}

@media (max-width: 768px) {
  .container {
    padding: 0 30px;
  }

  .header .container {
    flex-direction: column;
    padding-top: 10px;
    text-align: center;
    background-position: 33% 50%;
    min-height: 300px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .header {
    min-height: 320px;
  }

  .header h1 {
    margin-bottom: 5px;
    line-height: 110%;
    margin-top: 0px;
    font-size: 2.5rem;
  }

  .header h3 {
    margin-bottom: 10px;
  }

  .header small {
    font-size: x-small;
    margin-top: 200px;
  }

  h1 {
    display: flex;
    align-self: center;
    justify-content: center;
  }

  #contact .boxes {
    flex-direction: column;
    padding: 10px 0px;
    text-align: center;
    background-position: 33% 50%;
    min-height: 350px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: center;
    width: 300px !important;
  }
}

.navbar .mobileNavigation {
  display: none;
}

.container .mobileCollab {
  display: none;
}

@media (max-width: 990px) {
  .container .ffu8bod {
    display: none;
  }

  .container .mobileCollab {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 990px) {
  .navbar .navigation {
    display: none;
  }

  .navbar .mobileNavigation {
    display: flex;
    align-items: center;
  }

  .navbar .mobileNavigation .hamburger {
    position: absolute;
    right: 5%;
    cursor: pointer;
  }
  .navbar nav {
    opacity: 0.9 !important;
  }

  .navbar nav ul {
    padding-top: 7px;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0px;
    top: 60px;
    width: 100%;
    background-color: #005c99;
    border-top: 2px solid #accadd;
    align-items: baseline;
  }

  .navbar nav ul li {
    padding-left: 5%;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .navbar button {
    padding-left: 0px;
    display: flex;
    padding-bottom: 10px;
  }
}

#contact .boxes {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 750px;
}

.boxes #content {
  display: block;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 150px;
  padding: 10px 10px;
  border: #e6e8e9 solid 1px;
  margin-bottom: 20px;
}

.boxes #content p {
  margin-bottom: 5px;
}

.boxes .vscMail {
  vertical-align: middle;
  height: auto;
}

.biBus {
  vertical-align: middle;
  margin-right: 5px;
}

.boxes .mailAddress {
  padding-bottom: 5px;
  vertical-align: middle;
}

.mapImg {
  width: 300px;
  padding: 5px;
}

.logoBox{
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100%/6);
  height: 100px;
}

@media (max-width: 1400px) {
  .logoBox{
    width: calc(100% / 3);
  }
}

@media (max-width: 1000px) {
  .logoBox{
    width: calc(100% / 2);
  }
}

@media (max-width: 700px) {
  .logoBox{
    width: 100%;
  }
}

.logoImg {
  width: 150px;
  padding: 10px;
}

.navbar .logo img {
  height: 45px;
  margin-top: 5px;
}

@media (max-width: 450px) {
  .navbar .logo img {
    height: 37px;
    margin-top: 3px;
  }
}

#approach .boxes {
  padding: 0px;
  display: flex;
}
